// const initialState = {
//   application_stage: "DOCUMENTS_UPLOAD_PENDING",
//   form: {},
// };
const initialState = {
  application_stage: "FILL_FORM",
  form: {},
};

const fullpaymentReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "CHANGE_APPLICATION_STAGE":
      return { ...state, application_stage: payload };

    case "UPDATE_FORM":
      return {
        ...state,
        form: { ...payload },
      };

    default:
      return state;
  }
};

export default fullpaymentReducer;
