import { createTheme } from "@material-ui/core/styles";

import GothamBook from "./assets/css/fonts/gotham/Gotham-Book.otf";
//import GothamBookMedium from "./assets/css/fonts/gotham/Gotham-Medium.otf";

const gotham = {
  fontFamily: "GothamBook",
  fontStyle: "normal",
  fontDisplay: "swap",
  //fontWeight: 400,
  src: `
    local('Gotham Book'),
    url(${GothamBook})
  `,
};
const sans = {
  fontFamily: "'General Sans', sans-serif",
  fontStyle: "normal",
  fontDisplay: "swap",
  //fontWeight: 400,GeneralSans
  src: "url(https://api.fontshare.com/css?f[]=general-sans@200&display=swap)",
};

// const theme = createMuiTheme({
//   typography: {
//     fontFamily:
//       "GothamBook, -apple-system, BlinkMacSystemFont, 'Helvetica Neue'",
//   },
//   overrides: {
//     MuiCssBaseline: {
//       "@global": {
//         "@font-face": [gotham],
//       },
//     },
//   },
//   palette: {
//     primary: {
//       main: "#191919",
//     },
//     secondary: {
//       main: "#26BB3B",
//       green: "#cfe8fc",
//     },
//     error: {
//       main: "#ff0000",
//     },
//     background: {
//       default: "#FFFF",
//     },
//   },
// });
const ather = createTheme({
  palette: {
    common: {
      white: "#FFFFFF"
    },
    primary: {
      main: "#26BB3B", //green
      sub: "#ffffff", //white
      text: "#FFFF",
      text1: "#191919", //black
      text3: "#6A6A6A", //grey-dark
      text4: "#F4F3F4",
      text5: "#292A2D",
      dropDownBgcolor: "#F4F3F4",
      dropDowntextcolor: "#292A2D",
      formSaveandNext: "#191919",
      applicationStrip: "#d9efdb",
      applicationstripButton: '"#292A2D',
      applicationloading: "#d9efdb",
      leaseDark: "#9ED6A3",
      leaseLight: "#d9efdb",
      leaseMedium: "#9ED6A3",
    },
    secondary: {
      main: "#707070",
      tenurebutton: "00d5e5",
    },
  },
  body: {
    borderRadius: "0px",
  },
  body1: {
    borderRadius: "1px",
  },
  typography: {
    subtitle1: {
      fontFamily: "GothamBookMedium",
      fontweight: 300,
      fontSize: "18px",
    },
    fontFamily:
      "GothamBook, -apple-system, BlinkMacSystemFont, 'Helvetica Neue'",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [gotham],

        body: {
          scrollbarColor: "#26BB3B #26BB3B",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            background:
              "linear-gradient(to right bottom, rgb(171, 235, 162), rgb(28, 218, 69))",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            //borderRadius: 8,
            backgroundColor: "#26BB3B",

            background:
              "linear-gradient(to right bottom, rgb(171, 235, 162), rgb(28, 218, 69))",
            //minHeight: 24,
            // border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              background:
                "linear-gradient(to right bottom, rgb(171, 235, 162), rgb(28, 218, 69))",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              background:
                "linear-gradient(to right bottom, rgb(171, 235, 162), rgb(28, 218, 69))",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              background:
                "linear-gradient(to right bottom, rgb(171, 235, 162), rgb(28, 218, 69))",
            },
        },
      },
    },
  },
});
const tork = createTheme({
  palette: {
    common: {
      white: "#ffffff",
    },
    primary: {
      main: "#0dcaf0",
      sub: "#ffffff",
      text: "#74819D",
      text1: "#231F20",
      text3: "#74819D",
      text5: "#74819D",
      dropDownBgcolor: "#0dcaf0",
      dropDowntextcolor: "#231F20",
      formSaveandNext: "#0dcaf0",
      applicationStrip: "#0dcaf0",
      applicationstripButton: '"#292A2D',
      applicationloading: "#CEF4FC",
      leaseDark: "#0dcaf0",
      leaseLight: "#E8E8E8",
      leaseMedium: "#E8E8E8",
    },
    secondary: {
      main: "#E8E8E8",
      tenurebutton: "00d5e5",
    },
  },
  body: {
    borderRadius: "0px",
  },
  body1: {
    borderRadius: "100px",
  },
  typography: {
    subtitle1: {
      fontFamily: "'General Sans', sans-serif",
      fontSize: "18px",
      fontweight: 600,
      lineHeight: "28px",
    },
    fontFamily: "'General Sans', sans-serif",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [sans],
        body: {
          scrollbarColor: "#0dcaf0 #0dcaf0",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            background: "linear-gradient(to right bottom, #0dcaf0, #0dcaf0)",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            //borderRadius: 8,
            backgroundColor: "#0dcaf0",

            background: "linear-gradient(to right bottom, #0dcaf0, #0dcaf0)",
            //minHeight: 24,
            // border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              background: "linear-gradient(to right bottom, #0dcaf0, #0dcaf0)",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              background: "linear-gradient(to right bottom, #0dcaf0, #0dcaf0)",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              background: "linear-gradient(to right bottom, #0dcaf0, #0dcaf0)",
            },
        },
      },
    },
  },
});
const hero = createTheme({
  shape: {
    borderRadius: "50px",
  },
  palette: {
    common: {
      white: "#F4F3F4",
    },
    primary: {
      // main: '#00d5e5',
      main: "#00d5e5",
      sub: "#ffffff",
      text: "#74819D",
      text1: "#231F20",
      text3: "#74819D",
      text5: "#74819D",
      dropDownBgcolor: "#00d5e5",
      dropDowntextcolor: "#231F20",
      formSaveandNext: "#00d5e5",
      applicationStrip: "#00d5e5",
      applicationstripButton: '"#292A2D',
      leaseDark: "#00d5e5",
      leaseLight: "#F4F3F4",
      leaseMedium: "#E8E8E8",
    },
    secondary: {
      main: "#999898",
      tenurebutton: "00d5e5", //tenure button
    },
  },
  body: {
    borderRadius: "5px",
  },
  body1: {
    borderRadius: "100px",
  },
  typography: {
    subtitle1: {
      fontFamily: "GothamBookMedium",
      fontSize: "18px",
      fontweight: 600,
      lineHeight: "28px",
    },
    fontFamily:
      "GothamBook, -apple-system, BlinkMacSystemFont, 'Helvetica Neue'",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [gotham],
        body: {
          scrollbarColor: "#00d5e5 #0dcaf0",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            background: "linear-gradient(to right bottom, #00d5e5, #00d5e5)",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            //borderRadius: 8,
            backgroundColor: "#00d5e5",

            background: "linear-gradient(to right bottom, #00d5e5, #00d5e5)",
            //minHeight: 24,
            // border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              background: "linear-gradient(to right bottom, #00d5e5, #00d5e5)",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              background: "linear-gradient(to right bottom, #00d5e5, #00d5e5)",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              background: "linear-gradient(to right bottom, #00d5e5, #00d5e5)",
            },
        },
      },
    },
  },
});

// export default theme;
export default ather;
export { tork };
export { hero };
