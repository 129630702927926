import React from "react";

import Input from "@mui/material/Input";

export default function BankListContainer({ city }) {
  return (
    <>
      <div className="font-NeurialGrotesk text-lg flex text-midnight font-normal sm:text-md">
        Loan EMI Calculator
        <div className="text-sm ml-2 font-normal sm:text-xs">
          <Input
            disabled
            defaultValue={city}
            sx={{
              "& > :not(style)": {
                color: "#191919",
                fontFamily: "NeurialGrotesk",
                width: 75,
                fontSize: 14,
                fontWeight: 400,
              },
            }}
          ></Input>
        </div>
      </div>
    </>
  );
}
