import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion";
import axios from "services/Axios";

import BankListContainer from "./BankListContainer";
import LoadingPage from "./Loadingpage";
import "App.css";

let loanData, BankListData;

//animation
const transition = { duration: 0.5, ease: "easeInOut", staggerChildren: 0 };
const postPreviewVariants = {
  initial: { x: "100%", opacity: 0 },
  enter: { x: "0%", opacity: 1, transition },
  exit: { x: "-100%", opacity: 0, transition },
};

export default function FinanceList() {
  const [currStage, setStage] = useState("Fetching Plans");
  const [data, setData] = useState([]);
  const [city, setCity] = useState();
  const [asset, setAsset] = useState([]);
  const [plan, setPlans] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  //const[loanData,setLoanData]=useState([]);
  const { jwt } = useParams();

  // fetching data from api
  const getBanklist = () => {
    const endpoint = "api/loan/get_cache_data";

    const payload = { jwt };

    axios
      .post(endpoint, payload)
      .then((response) => {
        BankListData = response.data.data;

        setData(BankListData.list);
        setCity(BankListData.city);
        setAsset(BankListData.assets);
        setPlans(BankListData.plans);
        setIsLoaded(true);
      })
      .catch((e) => {
        setStage("No plans Found");
      });
  };

  useEffect(() => getBanklist(), []);

  return isLoaded ? (
    <>
      <div className="relative text-darkgrey overflow-hidden mx-auto xl:w-1/2 mt-48 p-8  lg:w-1/2 md:w-3/4 sm:w-full sm:mt-0 sm:p-4">
        {/* <div className="sm:w-full lg:w-1/2 md:w-1/2 relative text-darkgrey p-8 overflow-hidden bg-athergrey mx-auto lg:mt-48 md:mt-24 sm:mt:0"> */}
        <BankListContainer city={city} />
        <motion.div
          initial="exit"
          animate="enter"
          exit="exit"
          variants={postPreviewVariants}
        >
          <div className="mt-10 text-midnight text-sm ">
            Select your financier
          </div>
          <div></div>
          <Paper
            classname="list-title"
            sx={{
              maxWidth: 822,
              my: 0,
              mt: 1.5,
              p: 1.5,
              background: "#f3f3f3",
              "box-shadow": "none",
              textAlign: "left",
            }}
          >
            <Grid
              container
              wrap="nowrap"
              spacing={1.5}
              className="financier-list-header"
            >
              <Grid item xs={2.5} md={2.5} className="sub-header">
                <div>
                  <span className="sm:text-xs p-0">Name of financier</span>
                </div>
              </Grid>
              <Grid item xs={2.5} md={2.5}>
                <div>
                  <span className="sm:text-xs p-0">Interest rate (flat)</span>
                </div>
              </Grid>
              <Grid item xs={2} md={2}>
                <div>
                  <span className="sm:text-xs p-0">Loan amount</span>
                </div>
              </Grid>
              <Grid item xs={2} md={2}>
                <div>
                  <span className="sm:text-xs p-0">Tenure</span>
                </div>
              </Grid>
              <Grid item xs={2} md={2}>
                <div>
                  <span className="sm:text-xs p-0">EMI</span>
                </div>
              </Grid>
              <Grid item xs={1} md={1}></Grid>
            </Grid>
          </Paper>

          <Grid
            container
            rowSpacing={1}
            sx={{ background: "#f3f3f3", height: "auto" }}
          >
            {/* {data.map((listEle)=>{ */}
            {data.map((listEle) => {
              return (
                <Grid item xs={12}>
                  <Paper sx={{ maxWidth: "100%", my: 0, mx: 0, p: 1.5 }}>
                    <Grid container wrap="nowrap" className="bg-white">
                      <Grid item xs={2.5} md={2.5} lg={2.5} xl={2.5}>
                        <div>
                          <span className="text-darkgrey sm:text-xs  p-0">
                            <img
                              src={listEle.financier_image_url}
                              alt={listEle.financier_name}
                            />
                          </span>
                          <p className="text-midnight sm:text-xxs">
                            {listEle.financier_name}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={2.5} md={2.5} lg={2.5} xl={2.5}>
                        <div>
                          <span className="text-darkgrey sm:text-xs  p-0">
                            {listEle.min_roi ? listEle.min_roi + "%" : ""}
                            {listEle.max_roi
                              ? " -" + listEle.max_roi + "%"
                              : ""}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={2} md={2} lg={2} xl={2}>
                        <div>
                          <span className="text-darkgrey sm:text-xs p-0">
                            {listEle.loan_amount}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={2} md={2} lg={2} xl={2}>
                        <div>
                          <span className="text-darkgrey sm:text-xs p-0">
                            {listEle.tenure_from ? listEle.tenure_from : ""}
                            {listEle.tenure_to
                              ? " -" + listEle.tenure_to
                              : ""}{" "}
                            month
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={2} md={2} lg={2} xl={2}>
                        <div>
                          <span className="text-darkgrey sm:text-xs p-0 ">
                            Starting at ₹ {listEle.emi_amount} / month
                          </span>
                        </div>
                      </Grid>
                      {plan.map((data, index) => {
                        const bankName = data.provider.provider;

                        if (bankName === listEle.financier_name) {
                          loanData = data.plan;
                        }
                      })}

                      <Grid
                        item
                        xs={1}
                        md={1}
                        lg={1}
                        xl={1}
                        className="align_right align_center"
                      >
                        <div>
                          <Link
                            key={listEle.id}
                            to={{
                              pathname: "/financeDetails",
                              state: {
                                plan: plan,
                                asset: asset,
                                selectedFinancer: listEle.financier_name,
                                allbanks: data,
                                selectedBank: loanData,
                                city: city,
                                token: jwt,
                              },
                            }}
                          >
                            <ArrowForwardIcon className="iconClass" />
                          </Link>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </motion.div>
      </div>
    </>
  ) : (
    <LoadingPage text={currStage} />
  );
}
