const initialState = {
  name: "ather",
  vehicleVariant: ''
};

const oemReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "SET_OEM":
      return { ...state, ...payload };
    case "SET_OEM_VECHILE_VARIANT":
      return { ...state, vehicleVariant: payload.variant?.toUpperCase() ?? '' };

    default:
      return state;
  }
};

export default oemReducer;
