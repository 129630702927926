import React, { useState, useEffect } from "react";
import filter from "lodash/filter";
import ceil from "lodash/ceil";
import floor from "lodash/floor";
import sumBy from "lodash/sumBy";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FormControl,
  NativeSelect,
  sliderClasses,
  TextareaAutosize,
} from "@material-ui/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { currencyFormatter } from "services/CurrencyFormatter";
import round from "lodash/round";
import "App.css";
import BankListContainer from "./BankListContainer";

import Slider from "./Slider";

import LoadingPage from "./Loadingpage";
const transition = { duration: 0.5, ease: "easeInOut", staggerChildren: 0 };
let main_light_label = "w-2/5 text-darkgrey text-sm font-normal sm:text-xs";
let main_dark_label =
  "w-3/5 text-right text-midnight text-sm font-normal  sm:text-xs";

let sub_light_label =
  "w-full text-darkgrey text-xxs font-normal mt-2 sm:text-xxsx";

var type_name;

//for animation
const postVariants = {
  initial: { x: "-100%", opacity: 0 },
  enter: { x: "0", opacity: 1, transition },
  exit: { x: "100%", opacity: 0, transition },
};

export default function FinanceDetails1() {
  const location = useLocation();
  const { plan, asset, selectedFinancer, allbanks, city, selectedBank, token } =
    location.state;

  //function to load the selected bank
  function LoadBank(bank) {
    let current = bank;
    let current_bank_product = current.assets[0].sku;
    const veh = getVehicle(current_bank_product);
    let product = veh[2];
    let activeAsset = product;
    let type = current.plan_ltv_based_on;
    let processing_fee = current.processing_fee_percentage;
    const { type_price, type_name, other } = getcurrentassettype(
      type,
      activeAsset
    );
    return {
      activeAsset: activeAsset,
      type_price: type_price,
      type_name: type_name,
      other: other,
      processing_fee: processing_fee,
    };
  }
  function getBank() {
    return selectedBank;
  }

  const names = LoadBank(selectedBank);

  function getcurrentassettype(type, activeAsset) {
    if (type == "ON_ROAD_PRICE") {
      var type_price = activeAsset.on_road_price;
      type_name = "ON_ROAD_PRICE";

      var other =
        Number(activeAsset.comprehensive_insurance_price) +
        Number(activeAsset.addon_insurance_price);

      return { type_price, type_name, other };
    } else {
      var type_price = activeAsset.ex_showroom_price;
      type_name = "EX_SHOWROOM_PRICE";

      var other =
        Number(activeAsset.fame_subsidy) +
        Number(activeAsset.state_subsidy) +
        Number(activeAsset.non_vehicle_amount);

      return { type_price, type_name, other };
    }
  }
  const [isloaded, setIsLoaded] = useState(true);
  const [loanData, setLoanData] = useState(getBank());
  const [currentBank, setBankName] = useState(selectedFinancer);
  const [min_downpayment, setMinDownpayment] = useState(0);
  const [max_downpayment, setMaxDownpayment] = useState(0);
  const [tenure, setTenure] = useState(loanData.plan_properties.tenures[0]);
  const [price, setprice] = useState(names.type_price);
  //const[price,setprice]=useState(type_price);
  //const[displayprice,setDisplayprice]=useState(0);
  const [displayprice, setDisplayprice] = useState(names.type_price);
  const [assetprice, setassetprice] = useState(0);
  const [vehicle, setVehiclename] = useState(names.activeAsset.name);
  const [vehicle_details, setVehicle_details] = useState(names.activeAsset);
  const [assetName, setassetName] = useState(names.activeAsset.display_name);
  const [roi, setRoi] = useState(loanData.plan_properties.roi_and_month[0].roi);
  const [downpayment, setDownpayment] = useState(0);
  const [loanAmount, setLoanAmount] = useState(0);
  const [emi, setEmi] = useState(0);
  const [emiInterval, setEmiInterval] = useState(0);
  const [displayname, setDisplayname] = useState(type_name);
  const [processingFeePercent, setProcessingFeePercent] = useState(
    loanData.processing_fee_percentage
  );

  const [otherCharges, setotherCharges] = useState(
    getothercharges(loanData.plan_charges)
  );
  const [approxCharge, setApproxCharge] = useState(0);
  const exShowroomPrice = names.activeAsset.ex_showroom_price;
  const onRoadPrice = names.activeAsset.on_road_price;
  const fameSubsidy = names.activeAsset.fame_subsidy;
  const stateSubsidy = names.activeAsset.state_subsidy;
  //const nonVehiclePrice =names.vehiclePrice.non_vehicle_amount || names.activeAsset.non_vehicle_amount;
  const [comprehensiveInsurancePrice, setcomprehensiveprice] = useState(
    names.activeAsset.comprehensive_insurance_price
  );
  const [addonInsurancePrice, setaddonInsurancePrice] = useState(
    names.activeAsset.addon_insurance_price
  );
  const [roadTax, setroadTax] = useState(names.activeAsset.road_tax);
  const [smartCardFee, setsmartCardFee] = useState(
    names.activeAsset.smart_card_fee
  );
  const [rtoFee, setrtoFee] = useState(names.activeAsset.rto_registration_fee);
  const [totalDiscount, settotalDiscount] = useState(
    names.activeAsset.discount
  );
  const feature = loanData.plan_features.feature;
  const [selectedBank_vehicle, setVehicle] = useState(loanData.assets);

  function getothercharges(data) {
    const other_charges = sumBy(data, (pc) => {
      return Number(pc.value);
    });
    return other_charges;
  }

  useEffect(() => {
    setDefaultValues();
  }, []);
  const setDefaultValues = () => {
    var newprice = getAssetPrice(type_name, names.type_price, names.other);
    var newLoanAmount = getDownpayment(
      loanData.plan_properties,
      price,
      newprice
    );
    getEmi(newLoanAmount, roi, tenure);
    setEmiInterval(Number(loanData.plan_properties.emi_rates_interval));
    getApproxCharges(
      currentBank,
      roadTax,
      rtoFee,
      smartCardFee,
      addonInsurancePrice,
      comprehensiveInsurancePrice,
      newLoanAmount,
      processingFeePercent,
      otherCharges,
      displayname,
      emi
    );

    setIsLoaded(true);
  };
  //function for the calculation of approximate charges
  const getApproxCharges = (
    bank,
    roadTax,
    rtoFee,
    smartCard,
    addonInsurance,
    comprehensiveInsurance,
    loanAmount,
    processingFeePercent,
    otherCharges,
    computationType,
    emi = null
  ) => {
    const processingCharges = changeProcessingFee(
      loanAmount,
      processingFeePercent
    );

    let otherFee = 0;
    if (computationType === "EX_SHOWROOM_PRICE") {
      otherFee =
        Number(roadTax) +
        Number(rtoFee) +
        Number(smartCard) +
        Number(addonInsurance) +
        Number(comprehensiveInsurance) +
        processingCharges +
        otherCharges;
    } else {
      otherFee =
        Number(addonInsurance) +
        Number(comprehensiveInsurance) +
        processingCharges +
        otherCharges;
    }

    if (bank === "IDFC FIRST") {
      if (
        ["HYDERABAD", "VISAKHAPATNAM"].indexOf(loanData.plan_available_city) >
        -1
      ) {
        // Andhra Pradesh and Telangana
        if (Number(loanAmount) <= 70000) {
          otherFee = Number(otherFee) + 519; // Festive //older 100 is part of the plan total 500
        } else if (Number(loanAmount) > 70000 && Number(loanAmount) <= 100000) {
          otherFee = Number(otherFee) + 669; // Festive // 118 is part of the plan total 600
        } else if (Number(loanAmount) > 100000) {
          otherFee = Number(otherFee) + 1069; //Festive & assuming prices don't go above 2L // 118 is part of the plan total 1300
        }
      } else {
        // All other states

        if (Number(loanAmount) <= 100000) {
          otherFee = Number(otherFee) + 169; // //Festive // 118 is part of the plan total 200
        } else if (loanAmount > 100000) {
          otherFee = Number(otherFee) + 269; //Festive & assuming prices not above 2L// 118 is part of the plan total 318
        }
      }
    }

    if (bank === "IDFC FIRST" && emi !== null) {
      const advanceEmiCalculation =
        loanData.plan_emi_calculations.advance_emi_months || 0;
      otherFee = Number(advanceEmiCalculation) * emi + otherFee;
    }

    setApproxCharge(otherFee);
  };

  const changeProcessingFee = (loan_amount, percent) => {
    let processingFee = (percent * loan_amount) / 100;
    processingFee = round(processingFee);
    return processingFee;
  };

  //function to get downpayment,minimum downpayment,max downpayment
  function getDownpayment(value, price, newprice) {
    let min_downpayment = 0;
    let max_downpayment = 0;
    let minDpValue = Number(value.min_downpayment_value);
    let maxDpValue = Number(value.max_downpayment_value);
    if (minDpValue > 0 || maxDpValue > 0) {
      min_downpayment = minDpValue;
      max_downpayment = maxDpValue;
    } else {
      const actual_min_downpayment =
        (price * Number(value.min_downpayment_percent)) / 100;
      const actual_max_downpayment =
        (price * Number(value.max_downpayment_percent)) / 100;
      min_downpayment = actual_min_downpayment;
      min_downpayment = ceil(min_downpayment, -3);
      max_downpayment = actual_max_downpayment;
      max_downpayment = floor(max_downpayment, -3); //- 1000;
    }
    const newLoanAmount = newprice - min_downpayment;
    setMinDownpayment(min_downpayment);
    setMaxDownpayment(max_downpayment);
    setDownpayment(min_downpayment);
    setLoanAmount(newLoanAmount);
    return newLoanAmount;
  }

  //for getting assets price after deduction
  function getAssetPrice(type, price, other) {
    setDisplayname(type);
    if ((type = "ON_ROAD_PRICE")) {
      var newprice = Number(price) - Number(other);
      setassetprice(newprice);
      //setprice(newprice)

      return newprice;
    } else {
      var newprice = Number(price) - Number(other);
      setassetprice(newprice);

      // setprice(newprice)
      return newprice;
    }
  }
  //For selecting roi according to tenure selection
  const tenureHandler = (e) => {
    const { roi_and_month } = loanData.plan_properties;
    const newRoiAndMonth = filter(roi_and_month, (o) => {
      return Number(o.month) === Number(e.target.value);
    })[0];
    const newRoi = newRoiAndMonth.roi;
    setRoi(newRoi);
    const selected_tenure = e.target.value;
    setTenure(selected_tenure);

    const n_emi = getEmi(loanAmount, newRoi, selected_tenure);
    setEmi(n_emi);
    getApproxCharges(
      currentBank,
      roadTax,
      rtoFee,
      smartCardFee,
      addonInsurancePrice,
      comprehensiveInsurancePrice,
      loanAmount,
      processingFeePercent,
      otherCharges,
      displayname,
      n_emi
    );
  };
  //function to get vehicle depending on the selected banks
  function getVehicle(value) {
    var vehicle, id, vehicle_obj;
    asset.map((s, index) => {
      if (value == s.sku) {
        vehicle = s.name;
        id = index;
        vehicle_obj = s;
        //setVehiclename(vehicle);
        //setVehicle_details(s);
      }
    });
    return [vehicle, id, vehicle_obj];
  }

  //price updation depending of product selection
  const changepriceHandler = (e) => {
    asset.map((data, index) => {
      if (data.name == e.target.value) {
        var roadtax = data.road_tax;
        var rtofee = data.rto_registration_fee;
        var smartcardfee = data.smart_card_fee;
        var addoninsurance = data.addon_insurance_price;
        var comprehensiveinsurance = data.comprehensive_insurance_price;
        setroadTax(roadtax);
        setrtoFee(rtofee);
        setsmartCardFee(smartcardfee);
        setcomprehensiveprice(comprehensiveinsurance);
        setaddonInsurancePrice(addoninsurance);

        if (loanData.plan_ltv_based_on == "ON_ROAD_PRICE") {
          let amount = data.on_road_price;

          setVehiclename(data.name);
          setVehicle_details(data);
          setDisplayprice(data.on_road_price);
          setassetName(data.display_name);
          setDisplayname(loanData.plan_ltv_based_on);

          var other = data.comprehensive_insurance_price;
          var newprice = getAssetPrice(
            loanData.plan_ltv_based_on,
            amount,
            other
          );
          var amt = getDownpayment(loanData.plan_properties, amount, newprice);

          var emi = getEmi(amt, roi, tenure);
          getApproxCharges(
            currentBank,
            roadtax,
            rtofee,
            smartcardfee,
            addoninsurance,
            comprehensiveinsurance,
            amt,
            processingFeePercent,
            otherCharges,
            loanData.plan_ltv_based_on,
            emi
          );
        } else {
          let amount = data.ex_showroom_price;

          setDisplayprice(data.ex_showroom_price);
          setassetName(data.display_name);
          setDisplayname(loanData.plan_ltv_based_on);
          setVehiclename(data.name);
          setVehicle_details(data);
          var other = data.fame_subsidy;
          var newprice = getAssetPrice(
            loanData.plan_ltv_based_on,
            amount,
            other
          );
          var amt = getDownpayment(loanData.plan_properties, amount, newprice);

          var emi = getEmi(amt, roi, tenure);
          getApproxCharges(
            currentBank,
            roadTax,
            rtoFee,
            smartCardFee,
            addonInsurancePrice,
            comprehensiveinsurance,
            amt,
            processingFeePercent,
            otherCharges,
            loanData.plan_ltv_based_on,
            emi
          );
        }
      }
    });
  };

  //loan amount calculation  after slider changes
  const changeDownpayment = (e, v) => {
    const newDownpayment = v;
    const newLoanAmount = Number(assetprice) - newDownpayment; //- addonDpAmount;
    setDownpayment(newDownpayment);
    setLoanAmount(round(newLoanAmount));
    const n_emi = getEmi(newLoanAmount, roi, tenure);
    setEmi(n_emi);
    getApproxCharges(
      currentBank,
      roadTax,
      rtoFee,
      smartCardFee,
      addonInsurancePrice,
      comprehensiveInsurancePrice,
      loanAmount,
      processingFeePercent,
      otherCharges,
      displayname,
      n_emi
    );
  };
  //function for getting emi
  function getEmi(loanamount, roi, tenure) {
    const plan_emi_calculations = loanData.plan_emi_calculations.calculate_by;

    let newEmi = 0;
    let type;
    if (plan_emi_calculations === "REDUCING") {
      newEmi = calculateReducingEmi(loanamount, roi, tenure);
    } else {
      newEmi = calculateFlatEmi(loanamount, roi, tenure);
    }
    setEmi(newEmi);
    return newEmi;
  }
  //calculation of emi if it is reducing
  const calculateReducingEmi = (loanamount, roi, tenure) => {
    const emi =
      (loanamount + (loanamount * (roi / 100) * tenure) / 12) / tenure;

    return round(emi);
  };
  //calculation of emi if it is flat based
  const calculateFlatEmi = (loanamount, roi, tenure) => {
    let emi =
      ((((loanamount * roi) / 100) * tenure) / 12 + loanamount) / tenure;
    return round(emi);
  };
  //function to load the details onchange of bank dropdown
  function changeBankHandler(e) {
    plan.map((data, index) => {
      const bankName = data.provider.provider;
      if (e.target.value === bankName) {
        let bank_name = e.target.value;
        setBankName(bank_name);
        const current = data.plan;
        const names = LoadBank(current);
        setLoanData(current);
        //setVehicle(current.assets);
        let bankhandler_veh = current.assets[0].sku;
        bankhandler_veh = getVehicle(bankhandler_veh);

        let bankhandler_tenure = current.plan_properties.tenures[0];
        setTenure(bankhandler_tenure);

        const other_charges = getothercharges(current.plan_charges);
        setotherCharges(other_charges);

        if (current.plan_ltv_based_on == "ON_ROAD_PRICE") {
          let amount = bankhandler_veh[2].on_road_price;
          setVehiclename(bankhandler_veh[0]);
          setVehicle_details(bankhandler_veh[2]);

          setDisplayprice(bankhandler_veh[2].on_road_price);
          setassetName(bankhandler_veh[2].display_name);
          setDisplayname(loanData.plan_ltv_based_on);
          var other = bankhandler_veh[2].comprehensive_insurance_price;
          var newprice = getAssetPrice(
            current.plan_ltv_based_on,
            amount,
            other
          );
          var amt = getDownpayment(current.plan_properties, amount, newprice);
          var emi = getEmi(amt, roi, tenure);
          var processing_fee = current.processing_fee_percentage;
          setProcessingFeePercent(processing_fee);

          getApproxCharges(
            bank_name,
            roadTax,
            rtoFee,
            smartCardFee,
            addonInsurancePrice,
            comprehensiveInsurancePrice,
            amt,
            processing_fee,
            other_charges,
            current.plan_ltv_based_on,
            emi
          );
        } else {
          let amount = bankhandler_veh[2].ex_showroom_price;
          setVehiclename(bankhandler_veh[0]);
          setVehicle_details(bankhandler_veh[2]);

          setDisplayprice(bankhandler_veh[2].ex_showroom_price);
          setassetName(bankhandler_veh[2].display_name);
          setDisplayname(loanData.plan_ltv_based_on);
          var other = bankhandler_veh[2].fame_subsidy;
          var newprice = getAssetPrice(
            current.plan_ltv_based_on,
            amount,
            other
          );
          var amt = getDownpayment(current.plan_properties, amount, newprice);
          var emi = getEmi(amt, roi, tenure);
          var processing_fee = current.processing_fee_percentage;
          setProcessingFeePercent(processing_fee);

          getApproxCharges(
            bank_name,
            roadTax,
            rtoFee,
            smartCardFee,
            addonInsurancePrice,
            comprehensiveInsurancePrice,
            amt,
            processing_fee,
            other_charges,
            current.plan_ltv_based_on,
            emi
          );
        }
      }
    });
  }
  function getDisplayName(value) {
    if (value == "EX_SHOWROOM_PRICE") {
      var name = "Ex-showroom price";
    } else {
      var name = "On-Road price";
    }
    return name;
  }

  return isloaded ? (
    <>
      <div className="relative text-darkgrey overflow-hidden mx-auto  xl:w-1/2 mt-48 p-8  lg:w-1/2 mx-auto  md:w-3/4 mx-auto p-8 sm:w-full sm:mt-0 sm:p-4 ">
        <div className="text-sm text-midnight">
          <Link to={{ pathname: "/banklist/" + token }}>
            <ArrowBackIcon className="iconClass" />
            Back
          </Link>
        </div>
        <BankListContainer city={city} />

        <motion.div
          initial="exit"
          animate="enter"
          exit="exit"
          variants={postVariants}
          classname=" relative w-full pr-1.5 bg-athergrey inline sm:pr-0"
        >
          <div className="w-full md:flex mt-8 lg:flex mt-8 sm:mt-2">
            {/* <div className="main_container "> */}
            <div className="w-3/5 sm:w-full">
              <div className="mt-5  flex">
                <div className={main_light_label}>Your financer</div>
                <div className={main_dark_label}>
                  {/* <FormControl   width='200'> */}
                  <NativeSelect
                    defaultValue={1}
                    inputProps={{
                      name: "city",
                      id: "uncontrolled-native",
                    }}
                    style={{
                      color: "#191919",
                      fontSize: 14,
                      fontWeight: 400,
                      fontFamily: "NeurialGrotesk",
                    }}
                    className="sm:text-sm"
                    onChange={changeBankHandler}
                  >
                    <option value={selectedFinancer}>{selectedFinancer}</option>

                    {allbanks.map((s, index) => {
                      if (s.financier_name != selectedFinancer) {
                        return (
                          <option value={s.financier_name}>
                            {s.financier_name}
                          </option>
                        );
                      }
                    })}
                  </NativeSelect>
                  {/* </FormControl> */}
                </div>
              </div>

              <div className="mt-5  flex">
                <div className={main_light_label}>Your Variant</div>
                <div className={main_dark_label}>
                  <selectVehicle />

                  <NativeSelect
                    value={vehicle}
                    // defaultValue={vehicle}
                    inputProps={{
                      name: "assetss",
                      id: "uncontrolled-native",
                    }}
                    style={{
                      color: "#191919",
                      fontSize: 14,
                      fontWeight: 400,
                      fontFamily: "NeurialGrotesk",
                    }}
                    onChange={changepriceHandler}
                  >
                    {loanData.assets.map((vehicle, index) => {
                      let data = getVehicle(vehicle.sku);
                      return <option value={data[0]}>Ather {data[0]}</option>;
                    })}
                  </NativeSelect>
                </div>
              </div>

              {/* <Vehicle 
               onChange={changepriceHandler}
               product={loanData.assets}
               allproduct={asset}
               />
                */}
              <div className={sub_light_label}>
                Ather {assetName} {getDisplayName(displayname)} ₹ {displayprice}
              </div>

              <div className="mt-4 flex">
                <div className={main_light_label}>Loan tenure</div>
                <div className={main_dark_label}>
                  {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={tenure}
                            onChange={tenureHandler}
                            label="Age"
                           
                            
                            >
                           {loanData.plan_properties.tenures.map((data,index)=>{
                                         //return(<option value={data}>{data} Months</option>)
                                         return(<MenuItem value={data}>{data}</MenuItem>)  
                                    })}
                            
                            
                            </Select>
                            </FormControl> */}
                  {/* <FormControl > */}
                  <NativeSelect
                    value={loanData.plan_properties.tenures.find(
                      (obj) => obj === tenure
                    )}
                    inputProps={{
                      name: "tenure",
                      id: "uncontrolled-native",
                    }}
                    style={{
                      color: "#191919",
                      fontSize: 14,
                      fontWeight: 400,
                      fontFamily: "NeurialGrotesk",
                    }}
                    onChange={tenureHandler}
                  >
                    {loanData.plan_properties.tenures.map((data, index) => {
                      return <option value={data}>{data} Months</option>;
                    })}
                  </NativeSelect>
                  {/* </FormControl> */}
                </div>
              </div>
              <div className="mt-5 flex">
                <div className={main_light_label}>Down payment</div>
                <div className={main_dark_label}>{downpayment}</div>
              </div>

              <Slider
                step={emiInterval}
                min={min_downpayment}
                max={max_downpayment}
                onChange={changeDownpayment}
                leftText={`₹ ${currencyFormatter(min_downpayment)}`}
                rightText={`₹ ${currencyFormatter(max_downpayment)}`}
              ></Slider>
              <hr className="text-lightgrey mt-4" />
              <div>
                <div className={sub_light_label}>
                  {feature} -{" "}
                  <span className="text-midnight text-xs font-normal sm:text-xs">
                    {" "}
                    ₹{currencyFormatter(approxCharge)}
                  </span>
                </div>
              </div>
              <div className="w-1/3 text-midnight text-xs font-medium mt-5 sm:hidden">
                Features
              </div>
              <div className="flex">
                <div className="w-2/5 text-midnight text-xs font-normal mt-2 sm:hidden">
                  Minimal Documentation
                </div>
                <div className="w-3/5 text-midnight text-left text-xs font-normal mt-2 sm:hidden">
                  Attractive lower interest rates
                </div>
              </div>
            </div>

            <div
              className="bg-yellow h-48 rounded-sm mt-5 items-end ml-6 p-6 xl:w-2/5 md:w-3/5 sm:hidden"
              style={{ height: "fit-content" }}
            >
              <div className="text-midnight md:text-sm md:mt--12 sm:text-xs ">
                Monthly payable EMI
              </div>
              <div className="py-2 px-1 mt-5 flex">
                <div className="w-3/5 text-darkgrey text-sm font-normal sm:text-xs">
                  Asset Price (after excluding)
                </div>
                <div className="w-2/5 text-right text-midnight text-sm font-normal  sm:text-xs">
                  ₹ {assetprice}
                </div>
              </div>
              <div className="py-2 px-1 flex">
                <div className="w-3/5 text-darkgrey text-sm font-normal sm:text-xs">
                  Loan amount
                </div>
                <div className="w-2/5 text-right text-midnight text-sm font-normal  sm:text-xs">
                  ₹ {loanAmount}
                </div>
              </div>
              <div className="py-2 px-1 flex">
                <div className="w-3/5 text-darkgrey text-sm font-normal sm:text-xs">
                  Interest rate
                </div>
                <div className="w-2/5 text-right text-midnight text-sm font-normal  sm:text-xs">
                  {roi}%{" "}
                </div>
              </div>

              <div className="text-midnight text-md font-normal mt-2 md:text-sm ">
                ₹ {emi}/month
              </div>
            </div>
            <div className="sm:h-20 w-full mt-5 bg-yellow flex xl:hidden md:hidden">
              <div className="flex m-auto">
                <div className="px-4 py-2">
                  <div className="text-midnight text-xs font-normal">{roi}</div>
                  <div className="text-darkgrey text-xs font-normal mt-2">
                    Interest rate
                  </div>
                </div>
                <div className="px-4 py-2">
                  <div className="text-midnight text-xs font-normal">
                    {loanAmount}
                  </div>
                  <div className="text-darkgrey text-xs font-normal mt-2">
                    Loan amount
                  </div>
                </div>
                <div className="px-4 py-2">
                  <div className="text-midnight text-xs font-normal">{emi}</div>
                  <div className="text-midnight text-xs font-normal mt-2">
                    Monthly EMI
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  ) : (
    <LoadingPage text={"no data found"} />
  );
}
