import prebooking from "./prebooking";
import fullpayment from "./fullpayment";
import lease from "./lease";
import vehicle from "./vehicle";
import loan from "./loan";
import oem from "./oem";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  oem,
  vehicle,
  lease,
  loan,
  prebooking,
  fullpayment,
});

export default rootReducer;
