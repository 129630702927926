import { LeaseScreens } from "services/ApplicationScreens";

const initialState = {
  agreement: {},
  stage_number: 0,
  autovert_id: "",
  Authorization: "",
  application_original_stage: LeaseScreens.LANDING_PAGE,
  selectedPlan: {},
  form: {},
  plan: {},
  activePlans: [],
  activeAsset: {},
  application_stage: LeaseScreens.LANDING_PAGE,
  lease_plans: {},
  lease_plan: {},
  all_lease_plans: {},
  calculator_measurements: {
    plansCount: 0,
    termHeight: 0,
    emiCell: 0,
    inclusionCell: 0,
    retention_or_retainer: 0,
  },
  disclaimerAgreed: false,
};

const lease = (state = initialState, { payload, type }) => {
  switch (type) {
    case "AGREE_DISCLAIMER":
      return {
        ...state,
        disclaimerAgreed: true,
      };

    case "SET_LEASE_TOKEN_AND_AUTOVERT_ID":
      return {
        ...state,
        autovert_id: payload.autovertId,
        Authorization: payload.Authorization,
      };

    case "UPDATE_RETENTION_OR_RETAINER_CELL":
      return {
        ...state,
        calculator_measurements: {
          ...state.calculator_measurements,
          retention_or_retainer: payload,
        },
      };

    case "UPDATE_INCLUSIONS_CELL":
      return {
        ...state,
        calculator_measurements: {
          ...state.calculator_measurements,
          inclusionCell: payload,
        },
      };

    case "UPDATE_EMI_CELL":
      return {
        ...state,
        calculator_measurements: {
          ...state.calculator_measurements,
          emiCell: payload,
        },
      };

    case "UPDATE_TERMS_HEIGHT":
      return {
        ...state,
        calculator_measurements: {
          ...state.calculator_measurements,
          termHeight: payload,
        },
      };

    case "SET_PLANS_COUNT":
      return {
        ...state,
        calculator_measurements: {
          ...state.calculator_measurements,
          plansCount: payload,
        },
      };

    case "SET_LEASE_AGREEMENT":
      return {
        ...state,
        agreement: payload,
      };

    case "UPDATE_LEASE_PLANS":
      return {
        ...state,
        lease_plans: { ...state.lease_plans, plans: payload },
      };

    case "SET_LEASE_CHOSEN_PLAN":
      return { ...state, lease_plan: payload };

    case "SET_LEASE_FORM":
      return { ...state, form: payload };

    case "UPDATE_LEASE_ORIGINAL_STAGE":
      return { ...state, application_original_stage: payload };

    case "CHANGE_LEASE_APPLICATION_STAGE":
      return { ...state, application_stage: payload };

    case "SET_LEASE_PLANS":
      return { ...state, lease_plans: payload, all_lease_plans: payload };

    case "INCREMENT_LEASE_STAGE_NUMBER":
      return { ...state, stage_number: state.stage_number + 1 };

    case "DECREMENT_LEASE_STAGE_NUMBER":
      return { ...state, stage_number: state.stage_number - 1 };

    case "CHANGE_LEASE_STAGE_NUMBER":
      return { ...state, stage_number: payload };

    default:
      return state;
  }
};

export default lease;
