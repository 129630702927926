import instance from "axios";
import { isNil } from "lodash";
const ls = require('local-storage');

const API_HOST = process.env.REACT_APP_API_URL;

let axios = instance.create({
  baseURL: API_HOST,
  timeout: 29500,
});

axios.interceptors.request.use(function (config) {
  // Add the client token to the header
  const clientToken = ls.get("clientToken");
  if (!isNil(clientToken)) {
    config.headers["X-Api-Key"] = clientToken;
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    let errorText = null;
    let statusCode = 400;

    if (error.response) {
      statusCode = error.response.status;
      errorText =
        error.response.data.response ||
        error.response.data.message ||
        "Bad request, Try later.";
    } else if (error.request) {
      errorText = "Something went wrong, try later !";
    } else {
      errorText = "Please check your internet connection";
    }

    return Promise.reject({ errorText, statusCode });
  }
);

export default axios;
