const initialState = { application_stage: "BIKE_SELECTION", form: {} };

const prebookingReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "SELECT_BIKE":
      return {
        ...state,
        ...payload,
        application_stage: "FILL_USER_DETAILS",
      };

    case "CHANGE_STATUS":
      return {
        ...state,
        application_stage: payload,
      };

    case "FILL_FORM":
      return {
        ...state,
        form: payload,
      };

    default:
      return state;
  }
};

export default prebookingReducer;
