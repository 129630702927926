import LoadingPage from "components/static/LoadingPage";

import FinanceDetails1 from "components/new/FinanceDetails1";
import { AnimatePresence } from "framer-motion";
import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import FinanceList from "components/new/FinanceList";
import "./App.css";

const PageNotFound = lazy(() => import("pages/PageNotFound"));
// const LeaseApplication = lazy(() => import("pages/leaseapplication"));

const FullPayment = lazy(() => import("pages/fullpayment"));

const LoanApplication = lazy(() => import("pages/loan"));

const LeaseApplication = lazy(() => import("pages/lease"));

const PrebookingLandingPage = lazy(() =>
  import("pages/prebooking/LandingPage")
);

const AtherFaq = lazy(() => import("pages/Faq/AtherFaq"));

const PrebookingOrderSummary = lazy(() =>
  import("pages/prebooking/OrderSummary")
);

const LeasePlans = lazy(() => import("pages/plans"));

const LeasePlan = lazy(() => import("pages/lease/plans"));
const LoanPlan = lazy(() => import("pages/loan/plans"));
const GreenEmi = lazy(() => import("pages/loan/components/forms/GreenEmi"));
const GreenEmiFormMessage = lazy(() =>
  import("pages/loan/components/forms/Greenemisuccess")
);

// Delete these routes before deploying production

const ViewLeasePlans = lazy(() => import("pages/lease/ViewLeasePlans"));
const CityWiseLeasePlans = lazy(() =>
  import("pages/lease/CityWiseLeaseCalculator")
);

// const DealerLeasePlan = lazy(() => import("pages/dealer-plans/LeasePlans"));
const DealerLeasePlan2 = lazy(() => import("pages/dealer-plans/LeasePlans"));
const DealerLoanPlan = lazy(() => import("pages/dealer-plans/LoanPlans"));

const LeaseAgreement = lazy(() => import("pages/lease/agreements"));

const ComparisonSheet = lazy(() => import("pages/plans/ComparisonSheet"));

const KineticGreen = lazy(() => import("pages/kinetic-green"));
const KineticGreenView = lazy(() => import("pages/kinetic-green/KgView"));

const MG = lazy(() => import("pages/morris-garage"))

const Routes = () => {
  return (
    <>
      <Router>
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            <Route path="/compare-plans" component={ComparisonSheet} exact />
            <Route path="/green-emi/:autovert_id" component={GreenEmi} exact />
            <Route path="/success" component={GreenEmiFormMessage} exact />
            <Route path="/ather-faq" component={AtherFaq} exact />
            <Route
              path="/dealer-lease-plan"
              component={DealerLeasePlan2}
              exact
            />
            <Route
              path="/city-wise-lease-plans"
              component={CityWiseLeasePlans}
              exact
            />
            <Route
              path="/view-lease-plans/:sku/:city"
              component={ViewLeasePlans}
              exact
            />
            <Route path="/dealer-loan-plan" component={DealerLoanPlan} exact />
            <Route path="/lease/:id" component={LeaseApplication} exact />
            <Route
              path="/lease/:autovert_id/agreement"
              component={LeaseAgreement}
              exact
            />
            <Route path="/loan/:id" component={LoanApplication} exact />
            <Route path="/plans/:oem/:model" component={LeasePlans} exact />
            <Route path="/lease-plans/:jwt" component={LeasePlan} exact />
            <Route path="/loan-plans/:jwt" component={LoanPlan} exact />
            <Route
              path="/pay-at-once/:autovert_id"
              component={FullPayment}
              exact
            />
            <Route
              path="/product/:id/prebook"
              component={PrebookingLandingPage}
              exact
            />
            <Route
              path="/prebook/:id/summary"
              component={PrebookingOrderSummary}
              exact
            />
            <Route
              path="/morris-garage"
              component={MG}
              exact
            />
            <Route
              path="/kinetic-green"
              component={KineticGreen}
              exact
            />
            <Route
              path="/kinetic-green/:autovertId/view"
              component={KineticGreenView}
              exact
            />
            <Route
              render={({ location }) => (
                <AnimatePresence exitBeforeEnter initial={false}>
                  <Switch location={location} key={location.pathname}>
                    <Route
                      exact
                      path="/banklist/:jwt"
                      component={FinanceList}
                    />
                    <Route
                      exact
                      path="/financeDetails"
                      component={FinanceDetails1}
                    />
                  </Switch>
                </AnimatePresence>
              )}
            />
            <Route path="*" component={PageNotFound} exact />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default Routes;
