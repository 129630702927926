import React from "react";
import toUpper from "lodash/toUpper";
import Routes from "./routes";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import ather, { tork } from "./theme";
import { hero } from "./theme";
import { useSelector } from "react-redux";
import { SnackbarProvider } from 'notistack';

const App = () => {
  const { name } = useSelector((state) => state.oem);
  const oemName = toUpper(name);
  let appliedTheme;

  switch (oemName) {
    case "ATHER":
      appliedTheme = createTheme(ather);
      break;
    case "TORK":
      appliedTheme = createTheme(tork);
      break;
    case "HERO":
      appliedTheme = createTheme(hero);
      break;

    default:
      appliedTheme = createTheme(ather);
  }
  return (
    <>
      <MuiThemeProvider theme={appliedTheme}>
      <SnackbarProvider maxSnack={1} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <CssBaseline />
        <Routes />
        </SnackbarProvider>
      </MuiThemeProvider>
    </>
  );
};

export default App;
