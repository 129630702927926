const initialState = {};

const vehicleReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "SET_VEHICLE":
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default vehicleReducer;
