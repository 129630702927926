import React from "react";
import { currencyFormatter } from "services/CurrencyFormatter";
import Slider from "@mui/material/Slider";

export default function AvSlider({
  leftText,
  rightText,
  step = 1,
  min,
  max,
  onChange,
}) {
  return (
    <>
      <div className="w-100% mt-3">
        <Slider
          key={min}
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          sx={{
            size: "small",
            width: "100%",
            height: 2,
            color: "success.main",
            "& .MuiSlider-thumb": {
              height: 18,
              width: 18,
              borderRadius: "1px",
              color: "#31373E",
              boxShadow: "none",
              "&:focus": {
                boxShadow: "red",
              },
            },
            "& .MuiSlider-track": {
              color: "#31373E",
            },
            "& .MuiSlider-rail": {
              color: "#D7D7D7",
            },
          }}
        />
      </div>
      <div className="flex w-full">
        <div className="text-left w-1/2">
          <span className="text-lightgrey text-xs">
            ₹ {currencyFormatter(leftText)}
          </span>
        </div>
        <div className="text-right w-1/2">
          <span className="text-lightgrey text-xs">
            ₹ {currencyFormatter(rightText)}
          </span>
        </div>
      </div>
    </>
  );
}
