import React from "react";
import PlugLogo from "assets/images/plug_logo.png";

const Loadingpage = ({ text, screenSize = "h-screen" }) => {
  return (
    <>
      <div className={`flex items-cente bg-grey ${screenSize}`}>
        <div className="w-1/2 m-auto">
          <img
            className="text-center m-auto h-12"
            src={PlugLogo}
            alt="PlugLogo"
          />
          <h6 className="text-center uppercase mt-8 font-bold tracking-widest">
            {text}
          </h6>
        </div>
      </div>
    </>
  );
};

export default Loadingpage;

