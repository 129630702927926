export const LoanScreens = {
  LANDING_PAGE: "APPLICATION_CREATED",
  YOUR_DETAILS: "YOUR_DETAILS",
  ADDRESS_DETAILS: "ADDRESS_DETAILS",
  PROFESSIONAL_DETAILS: "PROFESSIONAL_DETAILS",
  DOCUMENTS_UPLOAD: "DOCUMENTS_UPLOAD",
  DOCUMENTS_APPROVAL_PENDING: "DOCUMENTS_APPROVAL_PENDING",
  APPLICATION_REJECTED: "APPLICATION_REJECTED",
  APPLICATION_CANCLLED: "CANCELLED",
};

export const LeaseScreens = {
  ...LoanScreens,
  AGREEMENT_SIGNED: "AGREEMENT_SIGNED",
  DOWNPAYMENT_MADE: "DOWNPAYMENT_MADE",
  DOCUMENTS_APPROVED: "DOCUMENTS_APPROVED",
  MANDATE_SIGNED: "EMANDATE_REGISTERED",
  LEASE_COMPLETED: "COMPLETED",
};

export const LoanApplicationNumbers = {
  0: LoanScreens.YOUR_DETAILS,
  1: LoanScreens.ADDRESS_DETAILS,
  2: LoanScreens.PROFESSIONAL_DETAILS,
  3: LoanScreens.DOCUMENTS_UPLOAD,
  4: LoanScreens.DOCUMENTS_APPROVAL_PENDING,
};

export const LeaseApplicationNumbers = {
  0: LeaseScreens.YOUR_DETAILS,
  1: LeaseScreens.ADDRESS_DETAILS,
  2: LeaseScreens.PROFESSIONAL_DETAILS,
  3: LeaseScreens.DOCUMENTS_UPLOAD,
  4: LeaseScreens.DOCUMENTS_APPROVAL_PENDING,
};
